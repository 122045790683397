<template>
    <div>
        
        <div class="normal-card bg-contcolor small-input-height">
            <div class="filter-area mb-md-5 mb-3">
                <div class="filter-box">
                    <v-form class="multi-col-validation">
                        <div class="d-flex justify-md-space-between align-center flex-md-nowrap flex-wrap">
                            <div class="flex-item w-sm-auto w-full">
                                <div class="d-flex align-center flex-md-nowrap flex-wrap">
                                    <!--<v-select
                                        v-model="stationID"
                                        :items="station_items"
                                        label="感測器編號"
                                        dense
                                        outlined
                                        item-text="device"
                                        item-value="device"
                                        @change="getDeviceRules()"
                                        class="me-3"
                                    ></v-select>-->

                                    <v-autocomplete
                                        v-model="stationID"
                                        :items="station_items"
                                        label="感測器編號"
                                        dense
                                        outlined
                                        item-text="device"
                                        item-value="device"
                                        @change="getDeviceRules()"
                                        class="me-3"
                                    >
                                    </v-autocomplete>

                                    <v-select
                                        v-model="rules"
                                        :items="rules_items"
                                        label="規則"
                                        dense
                                        outlined
                                        item-text="name"
                                        item-value="value"
                                    ></v-select>
                                    <v-btn class="ml-sm-5 mt-md-0 mt-0 mb-md-0 mt-sm-0 mt-3 w-sm-auto w-full"  color="primary" @click="searchData()">查詢</v-btn>
                                </div>
                            </div>
                        </div>
                        
                    </v-form>
                </div>
            </div>
            <v-row>
                <v-col cols="12" lg="9" md="8">
                    <div class="echartbja-chart" id="echartbja-chart"></div>
                </v-col>
                <v-col cols="12" lg="3" md="4">
                    <div class="sub-card">
                        <h2 class="m-title mb-0">
                            <div class="txt">
                                <span class="me-2">統計數據</span>
                                <span class="txt-yellow subtxt">[{{stationID}}]</span>
                            </div>
                        </h2>
                        <v-row class="rt-list ma-0 st">
                            <v-col cols="12" class="item-wrapper">
                                <div class="item">
                                    <div class="mlt col">
                                        <div class="dot purple"></div>
                                        <div class="title">平均值</div>
                                    </div>
                                    <div class="mlt col-auto">
                                        <div class="cont purple text-end">{{station_new.avgval}}</div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" class="item-wrapper">
                                <div class="item">
                                    <div class="mlt col">
                                        <div class="dot purple"></div>
                                        <div class="title">最大值</div>
                                    </div>
                                    <div class="mlt col-auto">
                                        <div class="cont purple text-end">{{station_new.maxval}}</div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" class="item-wrapper">
                                <div class="item">
                                    <div class="mlt col">
                                        <div class="dot"></div>
                                        <div class="title">波峰</div>
                                    </div>
                                    <div class="mlt col-auto">
                                        <div class="cont text-end">{{station_new.maxcount}}</div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" class="item-wrapper">
                                <div class="item">
                                    <div class="mlt col">
                                        <div class="dot"></div>
                                        <div class="title">歷時</div>
                                    </div>
                                    <div class="mlt col-auto">
                                        <div class="cont text-end">{{station_new.duration}}</div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div class="normal-card bg-contcolor">
                <h2 class="m-title pa-md-6 pa-2 mb-0">
                    <div class="txt">
                        歷程
                    </div> 
                </h2>

                <v-simple-table class="scroll-auto">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                污染類型
                            </th>
                            <th class="text-center">
                                開始時間
                            </th>
                            <th class="text-center">
                               結束時間
                            </th>
                            <th class="text-center">
                               平均值
                            </th>
                            <th class="text-center">
                               最大值
                            </th>
                            <th class="text-center">
                               波峰
                            </th>
                            <th class="text-center">
                               歷時(分)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in station_his_list" :key="index" @click="fetchRecords(item)">
                            <td>{{item.kind}}</td>
                            <td class="text-center">
                                {{item.stime}}
                            </td>
                            <td class="text-center">
                                {{item.etime}}
                            </td>
                            <td class="text-center">{{item.avgval}}</td>
                            <td class="text-center">{{item.maxval}}</td>
                            <td class="text-center">{{item.maxcount}}</td>
                            <td class="text-center">{{item.duration}}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
        </div>

    </div>
</template>

<script>

import axios from 'axios';
import config from '@/config.js';
var traceLineChart = null;
export default{
    name: 'Trace',
    data: () => ({
        stationID:"",
        station_new:{
            avgval:0,
            maxval:0,
            maxcount:0,
            duration:0,
        },
        station_his_list:[],
        station_items:[],
        rules:"",
        rules_items:[],
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(6)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){
        $(function() {
            $(".nav_mainTitle").text("事件簿");
        });

            var dataLabel=[],dataCont=[];

            //感測器編號
            const apiurl_stationInfo=config.geturl(config.API.infostation,{});
            axios.get(apiurl_stationInfo).then(response => {
                if(response.data !== ""){
                    let arry = response.data;
                    let station_arry=[];
                    $.each(arry,function(index,item){
                        if(item.id !== "" && item.id !== null && item.device !== "" && item.device !== null){
                            station_arry.push({
                                id:item.id,
                                device:item.device,
                            });
                        }
                    });
                    this.station_items = station_arry;
                }
            });


        
    },
    methods:{
        getDeviceRules(){
            const apiurl_getRules=config.geturl(config.API.single_device_rules,{id:this.stationID});
            axios.get(apiurl_getRules).then(response => {
                if(response.data != null){
                    this.rules_items=response.data;
                }
            });
        },
        searchData(){
            var self= this;
            //歷史示警資料
            const apiurl_trace=config.geturl(config.API.event_com,{id:this.stationID,rules_num:this.rules});
            axios.get(apiurl_trace).then(response => {
                if(response.data !== ""){

                    let warnID = 0;
                    let wa_stime=null,wa_etime=null;
                    //var hisData = response.data.his;
                    var hisData = response.data;
                    this.station_his_list = hisData;
                    $.each(hisData,function(index,item){

                            warnID = item.warn_id;
                            if(index+1 == response.data.length){
                                wa_etime = item.etime;
                            }
                            if(index == 0){
                                wa_stime = item.stime;
                            }
                            if(index == 0){
                                self.station_new.avgval = item.avgval;
                                self.station_new.maxval = item.maxval;
                                self.station_new.maxcount = item.maxcount;
                                self.station_new.duration = item.duration;
                            }
                    });

                    //第一筆示警資料詳細
                    let dataLabel=[],dataCont=[];
                    if(warnID > 0 && wa_stime != null && wa_etime != null){
                        let warnID_str = warnID.toString();
                        const apiurl_trace_details=config.geturl(config.API.event_com_details,{warnId:warnID_str,stime:wa_stime,etime:wa_etime});
                        axios.get(apiurl_trace_details).then(response => {
                            if(response.data !== ""){
                                let trace_details_arry = response.data;
                                
                                trace_details_arry.forEach(function(tda_item,index){
                                    dataLabel.push(tda_item.date);
                                    dataCont.push(tda_item.value);
                                });

                                //折線圖(示警每分鐘)
                                traceLineChart = echarts.init(document.getElementById('echartbja-chart'));
                                drawLineChart(dataLabel,dataCont,this.stationID);
                                
                            }
                        });
                    }else{
                        this.station_new.avgval = 0;
                        this.station_new.maxval = 0;
                        this.station_new.maxcount = 0;
                        this.station_new.duration = 0;
                        traceLineChart = null;
                        document.getElementById('echartbja-chart').innerHTML="";
                    }
                

                }
            });

            


        },
        fetchRecords(oitem){
            //console.log(index);

            this.stationID = oitem.device;
            this.rules = oitem.rules_num;
            this.searchData();
        }
    }
}


function drawLineChart(label,data,tootip_title){
    if(traceLineChart != null){
        traceLineChart.setOption({
            title: {
                text: '折線圖',
                textStyle:{
                    color:"#fff"
                }
            },
            tooltip: {
                trigger: 'axis'
            },
            textStyle:{
                color: '#fff'
            },
            legend:{
                textStyle:{
                    color:"#fff"
                }
            },
            grid: { // 直角坐标系内绘图网格
                left: '10%', // grid 组件离容器左侧的距离
                bottom: '15%', // grid 组件离容器下侧的距离
                width: "80%", // grid 组件的宽度。默认自适应
                height: "70%" // grid 组件的高度。默认自适应
            },
            xAxis: {
                data: label
            },
            yAxis: {},
            visualMap: {
                top: 10,
                right: 10,
                textStyle:{
                    color: '#fff'
                },
                pieces: [{
                    lte: 3,
                    color: '#00e800',
                     
                },{
                    gt: 3,
                    lte: 5,
                    color: '#ffff00',
                },{
                    gt: 5,
                    lte: 10,
                    color: '#ff7e00',
                },{
                    gt: 10,
                    lte: 15,
                    color: '#ff0000',
                },{
                    gt: 15,
                    lte: 20,
                    color: '#8f3f97',
                },{
                    gt: 20,
                    color: '#7e0023',
                }],
                outOfRange: {
                    color: '#999',
                },
            },
            series: [{
                name: tootip_title,
                type: 'line',
                data: data,
                /*markLine: {
                    silent: true,
                    data: [{ yAxis: 5 }],
                },*/
            }]
        })
    }

}

</script>